<template>
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="card">
        <DataTable
            :rows="15"
            :paginator="true"
            :value="collabs.data"
            :totalRecords="collabs.total"
            :rowHover="true"
            :loading="loading"
            :lazy="true"
            dataKey="id"
            responsiveLayout="scroll"
            filterDisplay="row"
            @page="query.page = $event.page + 1"
        >
<!--          v-model:filters="tableFilters"-->
          <template #header>
            <h5>Colaboraciones</h5>
          </template>

          <Column header="#">
            <template #body="{data}">
              <div v-tooltip.top="`Ir a detalles`">
                <Button :label="data.code" class="p-button-link" @click="goToCollabDetail(data.id)"/>
              </div>
            </template>
          </Column>

          <Column header="Summary">
            <template #body="{data}">
              Colaboracion del {{ formatDate(data.start_at) }}
              <Tag v-if="data.foodies_meeting" value="Foodiequedada" icon="pi pi-users"></Tag>
            </template>
          </Column>

          <Column filterField="type" header="Tipo" :showFilterMenu="false">
            <template #body="{data}">
              <div v-if="data.is_premium">
                premium
              </div>
             <div v-else>
               {{ data.type }}
             </div>
            </template>

            <template #filter>
              <Dropdown v-model="query.type" @change="applyQueryFilters('type')" :options="typeOptions"
                        optionLabel="name" optionValue="value"
                        placeholder="Type" class="p-column-filter">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{ typeMap(slotProps.value) }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  {{ slotProps.option.name }}
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column filterField="status" header="Status" :showFilterMenu="false">
            <template #body="{ data }">
              {{ data.status }}
            </template>

            <template #filter>
              <Dropdown v-model="query.status" @change="applyQueryFilters('status')" :options="statusOptions"
                        optionLabel="name" optionValue="value"
                        placeholder="Status" class="p-column-filter">
                <template #value="slotProps">
                  <span v-if="slotProps.value">{{ statusMap(slotProps.value) }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  {{ slotProps.option.name }}
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column header="Restaurante" :showFilterMenu="false">
            <template #body="{data}">
              <img
                  v-if="data.slim_restaurant.profile_picture"
                  :alt="data.slim_restaurant.name"
                  :src="data.slim_restaurant.profile_picture" width="32"/>
              <span class="image-text">{{ data?.slim_restaurant?.name }}</span>
            </template>

            <template #filter>
              <div class="p-d-flex p-jc-between">
                <div class="p-d-flex p-jc-end">
                    <span class="p-input-icon-left ">
                        <i class="pi pi-search"/>
                        <InputText v-model="query.restaurant_search" placeholder="Restaurante"/>
                    </span>
                </div>
              </div>
            </template>
          </Column>

          <template #loading>
            Cargando collabs
          </template>
          <template #empty>
            No se encontraron collabs
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Collab from "src/repositories/models/Collab";
import {FilterMatchMode} from 'primevue/api';
import moment from "moment";

export default {
  data() {
    return {
      collabs: [],
      query: {
        'limit': 15,
        'paginate': true,
        'with': 'slimRestaurant',
        'sort_by': 'id,desc'
      },
      loading: false,
      searchDebounce: null,
      tableFilters: { // This required to be defined but is no used
        'type': {value: null, matchMode: FilterMatchMode.EQUALS},
        'status': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      typeOptions: [
        {
          name: "Todos",
          value: 'all',
        },
        {
          name: "Publica",
          value: "public",
        },
        {
          name: "Privada",
          value: "private",
        },
        {
          name: "Premium",
          value: "premium",
        }
      ],
      statusOptions: [
        {
          name: "Todos",
          value: 'all',
        },
        {
          name: "Activa",
          value: "active",
        },
        {
          name: "Cancelada",
          value: "canceled",
        },
        {
          name: "Cerrada",
          value: "closed",
        }
      ]
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('YYYY-MM-D')
    },
    async getCollabs() {
      this.loading = true;

        this.$repository.collaborations
          .list(this.query)
          .then(({ data: response }) => {
            this.collabs = response
          })
          .catch(() => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Unable to get collabs', life: 1000})
          })
          .finally(() => {
            this.loading = false
          })
    },
    typeMap(type) {
      return Collab.typeMap(type)
    },
    statusMap(status) {
      return Collab.statusMap(status)
    },
    applyQueryFilters(key) {
      let value = null;

      if (key === 'status') {
        value = this.query.status;
      } else if (key === 'type') {
        value = this.query.type;
      }

      if (value === 'all') {
        if (key === 'status') {
          this.query.status = null;
        } else if (key === 'type') {
          this.query.type = null;
        }
      }

      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce)
      }

      this.searchDebounce = setTimeout(() => {
        this.getCollabs();
      }, 600)
    },
    goToCollabDetail(collabId) {
      this.$router.push({
        name: 'collaborations.show',
        params: {
          id: collabId
        }
      })
    }
  },
  watch: {
    'query.page'() {
      this.getCollabs()
    },
    'query.restaurant_search'() {
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce)
      }
      this.searchDebounce = setTimeout(() => {
        this.query.page = 1
        this.getCollabs()
      }, 600)
    }
  },
  mounted() {
    this.getCollabs();
  }
}
</script>

<style scoped>
</style>